import React, { useState, useEffect } from 'react';
import Card from '@salesforce/design-system-react/components/card';
import Button from '@salesforce/design-system-react/components/button';
import Input from '@salesforce/design-system-react/components/input';
import DataTable from '@salesforce/design-system-react/components/data-table';
import DataTableColumn from '@salesforce/design-system-react/components/data-table/column';
import DataTableCell from '@salesforce/design-system-react/components/data-table/cell';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import { API, Auth } from 'aws-amplify';
import Alert from '@salesforce/design-system-react/components/alert';
import AlertContainer from '@salesforce/design-system-react/components/alert/container';
import Icon from '@salesforce/design-system-react/components/icon';
import AWS from 'aws-sdk';
import Lambda from 'aws-sdk/clients/lambda';
AWS.config.update({ region: 'eu-south-1' });

function SalesforceCredentialsForm(props) {
  const [url, setUrl] = useState('');
  const [sfUserId, setSfUserId] = useState('');
  const [sfPassword, setSfPassword] = useState('');
  const [sfSecurityToken, setSfSecurityToken] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);
  const [protocol, setProtocol] = useState("");
  const [endpointUsername, setEndpointUsername] = useState("")
  const [endpointPassword, setEndpointPassword] = useState("")
  const [endpointPort, setEndpointPort] = useState("")
  const [endpointImplicit, setEndpointImplicit] = useState(false)
  const [endpointAccessKeyId, setEndpointAccessKeyId] = useState("")
  const [endpointSecretKey, setEndpointSecretKey] = useState("")
  const [endpointRegion, setEndpointRegion] = useState("")
  const [endpointUrl, setEndpointUrl] = useState("")
  const [tableItems, setTableItems] = useState([])
  const [alertMessage, setAlertMessage] = useState("")
  const [sfValidParameters, setSfValidParameters] = useState(false)
  const [sfOrgId, setSfOrgId] = useState("")
  const [ssmOrgId, setSsmOrgId] = useState("")


  useEffect(() => {
    let ignore = false;
    checkOrgId(props.orgid, props.user.username)
    if (!ignore) fetchjobdata()
    return () => { ignore = true; }
  }, []);
  const CustomDataTableCell = ({ children, ...props }) => (
    <DataTableCell {...props}>
      <a
        href="/"
        onClick={(event) => {
          event.preventDefault();
        }}
      >
        {children}
      </a>
    </DataTableCell>
  );

  function checkamplifyuser (err, data) {
    if (err) {console.log("amplify user doesn't exist"); console.log(err)}
    else{
      if (!JSON.parse(data.Payload).hasOwnProperty("ret_value")) {
        setAlertMessage('salesforce parameters not validated')
        setSfValidParameters(false)
        return
      }
      console.log(JSON.parse(JSON.parse(JSON.parse(data.Payload).body).ret_value))
      if (JSON.parse(JSON.parse(JSON.parse(data.Payload).body).ret_value)){
        setSfValidParameters(true)
      }
      else {
        setAlertMessage('salesforce parameters not validated')
        setSfValidParameters(false)
      }
    }
  }

  function orgIdSaveCallback(err, data)
  {
    if (err) {console.log('failed to create orgid ssm')}
    else {
      console.log('orgid saved successfully in ssm')
    }
  } 

  const checkOrgId = async (orgid, username) => {
    // 1. if AmplifyUser parameter exist : already verified, else: show something about need to verify
    const accessToken = getToken()
    const headers = { 'Content-Type': 'application/json', Authorization: accessToken }
    const search_payload = { body: { operation: 'get_value', key_name: username }, headers: headers }
    invokeLambdaDtmvr('parameter-dev', search_payload.body, checkamplifyuser)
    if (orgid) {
      const payload = { body: { operation: 'create', key_name: username + '_orgid', value: orgid } }
      invokeLambdaDtmvr('parameter-dev',payload.body, orgIdSaveCallback)
    }
  }
  const ActionsDataTableCell = ({ children, ...props }) => (

    <DataTableCell title={children} {...props}>
      <a href="#" onClick={(event) => {
        event.preventDefault();
        if (children === "create") {
          const url_object = new URL(props.item.url);
          const proto = url_object.protocol.substring(0, url_object.protocol.length - 1).toUpperCase();
          setProtocol(proto)
          setEndpointUrl(props.item.url)
        }
      }}>
        {children}
      </a>
    </DataTableCell>
  );
  ActionsDataTableCell.displayName = DataTableCell.displayName;
  CustomDataTableCell.displayName = DataTableCell.displayName;
  const columns = [
    <DataTableColumn
      key="job_id"
      label="Job Id"
      property="job_id"
    >
      <CustomDataTableCell />
    </DataTableColumn>,

    <DataTableColumn
      key="last run"
      label="Last Run"
      property="last run"
    />,

    <DataTableColumn key="status" label="Status" property="status" />,

    <DataTableColumn key="message" label="Message" property="message">
      <ActionsDataTableCell />
    </DataTableColumn>

  ];

  const items = [
    { 'id': '00C', 'job_id': '00C', 'status': 'configured', 'url': 'ftp://example.com/uploads', 'actions': 'update' },
    { 'id': '00D', 'job_id': '00D', 'status': 'not configured', 'url': 'ftp://example1.com/dwh', 'actions': 'configure' }
  ];

  
  function CheckSFCredentialsCallback(err, data)
  {
    if(err){setAlertMessage("wrong or invalid SF credentials"); console.log("wrong or invalid SF credentials")
      setSfOrgId("")
    }
    else
    {
      const variable = JSON.parse(data.Payload)
      if (! variable.hasOwnProperty("errorMessage"))
        {console.log('received orgid from salesforce')
        console.log(JSON.parse(JSON.parse(data.Payload).body).ret_value)
        const matching_orgid = JSON.parse(JSON.parse(data.Payload).body).ret_value
        // 2. get org_id from current session or from ssm parameter
      if (matching_orgid == true){
        const ssmparameter_payload= {operation:"setup_parameters",
        amplify_username: props.user.username,
        sf_username: sfUserId,  
        sf_password: sfPassword, 
        sf_endpoint: url,
        sf_security_token: sfSecurityToken}
  console.log("The payload with the org_id is: "+ssmparameter_payload)
  invokeLambdaDtmvr('parameter-dev',ssmparameter_payload, emptycallback)}}
        else {
          setAlertMessage('salesforce parameters not validated')
        }
    }}
  
  function OrgIdFromSSMCallback(err, data) {
    if (err) {console.log('error getting orgid from ssm parameter')}
    else {
      const ssmorgid = JSON.parse(JSON.parse(data.Payload).body).ret_value
      if (ssmorgid !== "missing parameter")
        {setSsmOrgId(ssmorgid)
        console.log('ssmorgid: '+ssmorgid)
        if (sfOrgId !== null && ssmOrgId !== null && sfOrgId !== undefined && ssmOrgId !== undefined ){
          if (sfOrgId == ssmOrgId)
          {
            console.log('sfOrgId: '+sfOrgId)
            console.log('ssmOrgId: '+ssmOrgId)
            console.log('this is the type of sfOrgid:' + typeof sfOrgId);
            console.log('this is the type of ssmorgId:' + typeof ssmOrgId);
            // verification successful

            // we save SfAuth parameter
            const ssmparameter_payload= {operation:"setup_parameters",
                                        amplify_username: props.user.username,
                                        sf_username: sfUserId,
                                        sf_password: sfPassword, 
                                        sf_endpoint: url,
                                        sf_security_token: sfSecurityToken}
            console.log("The payload with the org_id is: "+ssmparameter_payload)
            invokeLambdaDtmvr('parameter-dev',ssmparameter_payload, emptycallback)}
          // we save AmplifyUser
          // we save Sfuser
          // we delete orgid parameter

        }
      }
    }
  }

  function emptycallback(err,data){
    if (err) {console.log('error performing setup parameters')}
    else{
      console.log("successfully uploaded setup parameters")
  }}


  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setSubmitMessage(null);

    const body = {
      url,
      sfUserId,
      sfPassword,
      sfSecurityToken
    };
    // 1. use parameters sent in form to query salesforce 
    const orgid_payload = { body: { operation: 'fetch_orgid', sf_username: sfUserId, sf_security_token: sfSecurityToken, sf_password: sfPassword, amplify_username: props.user.username } }
    invokeLambdaDtmvr('parameter-dev',orgid_payload.body, CheckSFCredentialsCallback)

    // YAY
    
    /* API.post('apidatamover', '/parameter', search_payload)
      .then((response) => {
        // 3. compare them
        if (response === sfOrgId) {// 4. if they are equal create:  AmplifyUser with sf_username and sf_username_hash
          const sfUserIdHash = calculateHash(sfUserId)
          // SFUser with amplify_username
        }
      }) */
  }
  function calculateHash(string) {
    // TODO implement hash
  }

  const handleProtocolChange = event => {
    setProtocol(event.target.value)
  };

  async function getToken() {

    try {
      const user = await Auth.currentAuthenticatedUser();
      const accessToken = user.signInUserSession.idToken.jwtToken;
      return accessToken;
    }

    catch (error) {
      console.log('Error getting token:  ', error);
    }

  }


  const getSfOrgId = async (sfUserId, sfPassword, sfSecurityToken, url) => {
    const accessToken = await getToken()
    const headers = { 'Content-Type': 'application/json', 'Access-Control-Allow-Headers': '*', Authorization: accessToken, 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Methods': 'OPTIONS,POST,GET' }
    
    // const response = API.post('apidatamover', '/parameter', orgid_payload)
    // return response
  }

  function exploreObject(obj, path = '') {
    for (let key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        exploreObject(obj[key], `${path}.${key}`);
      } else {
        console.log(`${path}.${key}: ${obj[key]}`);
      }
    }
  }

  function invokeLambdaDtmvr(lambdaName, Payload, callbackFunction) {
    const request = Auth.currentCredentials()
        .then(credentials => {
          const lambda = new Lambda({
            credentials: Auth.essentialCredentials(credentials)
          });
          return lambda.invoke({
            FunctionName: lambdaName,
            Payload: JSON.stringify({ body: JSON.stringify(Payload) }),
          }, callbackFunction);
        })
      }

  function jobDetailsCallBack (err, data) {
    if (err) {console.log('error'); console.log(err)}
    else{console.log(data.Payload)}
  }

  const fetchjobdata = async (event) => {
    const accessToken = await getToken()
    const headers = { 'Content-Type': 'application/json', Authorization: accessToken }
    const payload = {
      headers: headers,
      body: { operation: "jobdetails", amplify_username: "marioamply" }
    }
    invokeLambdaDtmvr('parameter-dev', payload.body, jobDetailsCallBack )
    
    /* API.post('apidatamover', '/parameter', payload)
      .then((response) => {
        const first_array = response.ret_value
        let second_array = []
        for (let i = 0; i < first_array.length; i++) {
          const item = first_array[i];

          const job_id = item.job_id
          const id = item.job_id
          let status = ""
          let actions = ""

          if (item.status) {
            status = "configured"
            actions = "update"
          }
          else {
            status = "not configured"
            actions = "create"
          }
          const url = item.url

          // all variables declaration here and needed operations
          let row_object = { id: id, job_id: job_id, status: status, url: url, actions: actions }
          second_array.push(row_object)
        }
        setTableItems(second_array) 
      })*/
  }

  return (
    <div>
      {alertMessage.length > 0 && (
        <AlertContainer>
          <Alert
            icon={<Icon category="utility" name="error" />}
            labels={{
              heading: alertMessage
            }}
            variant="error"
          />
        </AlertContainer>)}
      {sfValidParameters === false && (
        <div className="slds-align_absolute-center">
          <Card heading="" className="container slds-size_2-of-3 slds-card">
            <div className="slds-media__body slds-card__body_inner slds-p-left_xx-large slds-p-right_xx-large">
              <div className="slds-text-heading_large">
                Enter your Salesforce credentials
              </div>
              <form onSubmit={handleSubmit}>
                <label>
                  <h1 className="slds-text-title_caps slds-p-vertical_medium">URL:</h1>
                  <Input type="text" value={url} onChange={event => setUrl(event.target.value)} />
                </label>
                <label>
                  <h1 className="slds-text-title_caps slds-p-vertical_medium">User ID:</h1>
                  <Input type="text" value={sfUserId} onChange={event => setSfUserId(event.target.value)} />
                </label>
                <label>
                  <h1 className="slds-text-title_caps slds-p-vertical_medium">Password:</h1>
                  <Input type="password" value={sfPassword} onChange={event => setSfPassword(event.target.value)} />
                </label>
                <label>
                  <h1 className="slds-text-title_caps slds-p-vertical_medium">Security Token:</h1>
                  <Input type="password" value={sfSecurityToken} onChange={event => setSfSecurityToken(event.target.value)} />
                </label>
                <div className="slds-align_absolute-center slds-p-top_medium">
                  <Button type="submit" variant="brand" disabled={isSubmitting}>Submit</Button>
                </div>
              </form>
              {submitMessage && <p>{submitMessage}</p>}
            </div></Card>
        </div>)}
      <div className="slds-align_absolute-center slds-p-top_medium">
        <Card heading="" className="container slds-size_2-of-3 slds-card">
          <div className="slds-text-heading_large slds-p-left_xx-large slds-p-bottom_medium">
            Job Status
          </div>
          <IconSettings iconPath="/assets/icons">
            <div style={{ overflow: 'auto' }} className="slds-p-left_xx-large slds-p-right_xx-large slds-p-bottom_medium">
              <DataTable items={tableItems} id="Endpoints-Table">
                {columns}
              </DataTable>
            </div>
          </IconSettings>
          <div className='slds-p-right_xx-large slds-p-left_xx-large slds-p-bottom_xx-large'>
          </div>
        </Card>
      </div>
    </div>
  );
}
export default SalesforceCredentialsForm;
export function invokeLambdaDtmvr(lambdaName, Payload, callbackFunction) {
  const request = Auth.currentCredentials()
      .then(credentials => {
        const lambda = new Lambda({
          credentials: Auth.essentialCredentials(credentials)
        });
        return lambda.invoke({
          FunctionName: lambdaName,
          Payload: JSON.stringify({ body: JSON.stringify(Payload) }),
        }, callbackFunction);
      })
    }

export function jobDetailsCallBack (err, data) {
  if (err) {console.log('error'); console.log(err)}
  else{console.log(data.Payload)}
}