import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Tabs from '@salesforce/design-system-react/components/tabs';
import TabsPanel from '@salesforce/design-system-react/components/tabs/panel';
import Button from '@salesforce/design-system-react/components/button';
import Input from '@salesforce/design-system-react/components/input';
import Alert from '@salesforce/design-system-react/components/alert';
import AlertContainer from '@salesforce/design-system-react/components/alert/container';
import Card from '@salesforce/design-system-react/components/card';
import Spinner from '@salesforce/design-system-react/components/spinner';


function CustomAuth(props) {
  const [authStatus, setAuthStatus] = useState('normal')
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorType, setErrorType] = useState('info');
  const [validCaptcha, setValidCaptcha] = useState(false);
  const recaptchaRef = React.createRef();
  const [code, setCode] = useState("");
  const [currentTab, setCurrentTab] = useState(0);
  const [forgotCodeLogin, setForgotCodeLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  function handleEmailChange(event) {
    setEmail(event.target.value);
  }
  function handlePasswordChange(event) {
    setPassword(event.target.value);
  }

  function switchForgotPassword(event) {
    event.preventDefault();
    setAuthStatus('forgotPass');
  }
  function handleConfirmPasswordChange(event) {
    setConfirmPassword(event.target.value);
  }
  function showAlert(style, message) {
    setErrorType(style);
    setErrorMessage(message);
  }

  function emptyError(event){
    setErrorType('');
    setErrorMessage('');
  }

  async function signUp(event) {
    event.preventDefault();
    if (!validCaptcha) {
      showAlert('error', 'you must complete captcha in order to signup')
      return;
    }
    try {
      await Auth.signUp({
        username,
        password,
        attributes: {
          email,
        }
      });
      setAuthStatus('confirmSignUp');
    } catch (error) {
      console.log('error signing up:', error);
      showAlert('error', 'error signing up')
    }
  }
  async function signIn(event) {
    event.preventDefault();
    setLoading(true)
    try {
      const user = await Auth.signIn(username, password);
      props.onSignIn(user);
    } catch (error) {
      console.log('error signing in', error);
      showAlert('error', 'error signing in: ' + error)
      if (error.code === 'UserNotConfirmedException')
      {
        setAuthStatus('confirmSignUp');
      }
    }
  }
  async function handleForgotPassword(event) {
    event.preventDefault();
    try {
      await Auth.forgotPassword(username);
      setAuthStatus('changePassword')
    } catch (error) {
      showAlert('error', error.message)
    }
  }

  async function changePassword(event) {
    event.preventDefault();
    try {
      await Auth.forgotPasswordSubmit(username, code, confirmPassword);
      setAuthStatus('normal')
      setPassword('')
    } catch (error) {
      showAlert('error', error.message)
    }
  }

  function handleUsernameChange(event) {
    setUsername(event.target.value);
  }
  async function resendConfirmationCode(event) {
    event.preventDefault()
    try {
      await Auth.resendSignUp(username);
      console.log('code resent successfully');
      showAlert('info', 'resent successfully')
    } catch (err) {
      console.log('error resending code: ', err);
      showAlert('error', 'error resending code')
    }
  }
  async function confirmSignUp(event) {
    event.preventDefault();
    try {
      await Auth.confirmSignUp(username, code);
      const user = await Auth.signIn(username, password);
      props.onSignIn(user);
    } catch (error) {
      console.log('error confirming sign up', error);
      showAlert('error', 'error confirming sign up')
    }
  }
  async function signOut() {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log('error signing out: ', error);
      showAlert('error', 'error signing out')
    }
  }
  async function handleRecaptchaChange(value) {
    if (value) {
      setValidCaptcha(true);
    }
  }

  return (
    <div className="slds" style={{...styles.container, justifycontent: 'center', boxSizing: 'border-box' , margin: '0 auto'}}>
    {loading && (<div style={{ position: 'relative', height: '15rem' }}>
				<Spinner
					size="large"
					variant="brand"
					assistiveText={{ label: 'Main Frame Loading...' }}
				/>
			</div>)}
    <div>
    {!loading && (
    <IconSettings iconPath="/assets/icons">
      <Card heading="" style={{padding: '1rem',
    }}>
    
    <div className="container slds">
    
      <div selectedindex={currentTab} onSelect={index => setCurrentTab(index)}>
        <div>
    <center><img src="./assets/images/global-header/datamoverlogo.png" alt="Logo" /></center>
          <div className="mx-auto col-4">
            {authStatus === 'normal' && (
              <Tabs>
                <TabsPanel label="Sign In">
                  <form onSubmit={signIn}>
                  <section className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
                    <div className="slds-col_padded">
                    <h1 className="slds-text-title_caps slds-p-vertical_medium">
                        Username:</h1>
                        <Input className="form-control" type="text" value={username} onChange={handleUsernameChange} placeholder='Enter your Username'/>
                    </div>
                    </section>
                    <section className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
                    <div className="slds-col_padded">
                    <h1 className="slds-text-title_caps slds-p-vertical_medium">
                        Password:</h1>
                        <Input className="form-control" type="password" value={password} onChange={handlePasswordChange} placeholder='Enter your Password'/>
                      
                    </div>
                    </section>
                    <div  style={{display: 'flex', marginTop: '1rem', alignItems: 'center'}} justifycontent="center" justifytext="center">
                    <Button variant="brand" type="submit" style={{display: "block", margin: "0 auto"}}>Sign In</Button>
                    
                    </div>
                    <div style={{display: 'flex', marginTop: '1rem', alignItems: 'center'}} justifycontent="center" justifytext="center">
                    <a href="" role="button" onClick={switchForgotPassword} style={{display: "block", margin: "0 auto"}}>Forgot password? Click me</a>
                    </div>
                    {forgotCodeLogin && <div style={{display: 'flex', marginTop: '1rem', alignItems: 'center'}} justifycontent="center" justifytext="center">
                    <a href="" role="button" onClick={resendConfirmationCode} style={{display: "block", margin: "0 auto"}}>Resend code</a>
                    </div>}
                  </form>
                </TabsPanel>
                <TabsPanel label="Sign Up">
                  <div className="mx-auto col-4">
                    <form onSubmit={signUp}>
                    <section className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
                    <div className="slds-col_padded">
                        <h1 className="slds-text-title_caps slds-p-vertical_medium">Username:</h1>
                          <Input className="form-control" type="text" value={username} onChange={handleUsernameChange} placeholder='Choose your Username'/>              
                     </div>
                      </section>
                      <section className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
                      
                      <div className="slds-col_padded">
                      <h1 className="slds-text-title_caps slds-p-vertical_medium">Email:</h1>
                          <Input className="form-control" type="email" value={email} onChange={handleEmailChange} placeholder='Enter your email address' />
                          </div>
                      
                      </section>
                      <section className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
                      <div className="slds-col_padded">
                        <h1 className="slds-text-title_caps slds-p-vertical_medium">Password:</h1>
                          <Input className="form-control" type="password" value={password} onChange={handlePasswordChange} placeholder='Choose a password'/>
                        
                      </div>
                      </section>
                      <section className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
                      <div className="slds-col_padded">
                      
                      <h1 className="slds-text-title_caps slds-p-vertical_medium">Confirm Password:</h1>
                          <Input className="form-control" type="password" value={confirmPassword} onChange={handleConfirmPasswordChange} placeholder='Confirm your password'/>
              
                      </div>
                      </section>
                      <section className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
                      <div className="slds-col_padded" style={{marginTop: '2rem'}}>
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey='6LdByTYlAAAAANbUFSV71DIWi4jPOggGb0o3d68x'
                        onChange={handleRecaptchaChange}
                      /></div>
                      </section>
                      <div  style={{flex: '1', marginTop: '2rem'}} justifycontent="center">
                        <Button variant="brand" type="submit" style={{display: "block", margin: "0 auto"}}>Sign Up</Button>
                      </div>
                    </form>
                  </div>
                </TabsPanel>
              </Tabs>
            )}
          </div>
        </div>
        {
        authStatus === 'forgotPass'
        && (
          <div>
              <h1 className="slds-text-title_caps slds-p-vertical_large">Password Recovery</h1>
              <form onSubmit={handleForgotPassword}>
              <section className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
              <div className="slds-col_padded">
                  <h1 className="slds-text-title_caps slds-p-vertical_medium">
                    Username:</h1>
                    <Input className="form-control" type="text" value={username} onChange={handleUsernameChange} placeholder='Please enter your Username'/>
                </div>  
                </section>
                <div  style={{flex: '1', marginTop: '1rem'}} justifycontent="center">
                <Button style={{display: "block", margin: "0 auto"}} variant="brand" type="submit">Forgot Password</Button>
                </div>
              </form>
          </div>
        )}

        {
        authStatus === 'changePassword'
        && (
          <div>
            <h1 className="slds-text-title_caps slds-p-vertical_large">Change Password</h1>
              <form onSubmit={changePassword}>
              <section className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
              <div className="slds-col_padded">
              <h1 className="slds-text-title_caps slds-p-vertical_medium">
                    Username:
                  </h1>
                    <Input className="form-control" type="text" value={username} onChange={handleUsernameChange} placeholder='Please enter your Username'/>
                    </div>
                  </section>
                  <section className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
                  <div className="slds-col_padded">
                  <h1 className="slds-text-title_caps slds-p-vertical_medium">
                    Code:</h1>
                    <Input className="form-control" type="text" value={code} onChange={(e) => setCode(e.target.value)}
                      placeholder="Please insert the code you received via email"
                    />
                    </div>
                  </section>
                  <section className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
                  <div className="slds-col_padded">
                  <h1 className="slds-text-title_caps slds-p-vertical_medium">
                    New password:</h1>
                    <Input className="form-control" type="password" value={password} onChange={handlePasswordChange} 
                    placeholder='Please choose your new password'/>
                  </div></section>
                  <section className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
                  <div className="slds-col_padded">
                  <h1 className="slds-text-title_caps slds-p-vertical_medium">
                    New password confirm:</h1>
                    <Input className="form-control" type="password" value={confirmPassword} onChange={handleConfirmPasswordChange} 
                    placeholder='Please confirm previous password'/>
                    </div>
                    </section>
                    <div  style={{flex: '1', marginTop: '1rem'}} justifycontent="center">
                        <Button variant="brand" type="submit" style={{display: "block", margin: "0 auto"}}>Change password</Button>
                </div>
              </form>
            </div>
        
        )}

        {
        authStatus === 'confirmSignUp'
        && (
          <div>
            <h1 className="slds-text-title_caps slds-p-vertical_large">Confirm Account</h1>
            
              <form onSubmit={confirmSignUp}>
              <section className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
              <div className="slds-col_padded">
              <h1 className="slds-text-title_caps slds-p-vertical_medium">
                    Username:</h1>
                    <Input
                      className="form-control"
                      type="text"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      placeholder="Please insert your username"
                    />
                    </div>
                </section>
                <section className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
                <div className="slds-col_padded">
                <h1 className="slds-text-title_caps slds-p-vertical_medium">
                    Confirmation code:</h1>
                    <Input
                      className="form-control"
                      type="text"
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                      placeholder="Please insert the code you received via email"
                    />
                  </div>
                </section>
                <div  style={{flex: '1', marginTop: '1rem'}} justifycontent="center">
                <Button variant="brand" type="submit" style={{display: "block", margin: "0 auto"}}>Confirm Account</Button>
                </div>
                <div style={{display: 'flex', marginTop: '1rem', alignItems: 'center'}} justifycontent="center" justifytext="center">
                    <a href="" role="button" onClick={resendConfirmationCode} style={{display: "block", margin: "0 auto"}}>Resend code</a>
                    </div>
                <div>
                  
                </div>
              </form>
            </div>
          
        )}
      </div>
      
      { errorMessage.length > 0 &&
        (<IconSettings iconPath="/assets/icons">
          <AlertContainer>
            <Alert variant={errorType}
              labels={{
                heading: errorMessage
              }}
              dismissible='true'
              onRequestClose={emptyError}
            />
          </AlertContainer>
        </IconSettings>)}
        
    </div></Card></IconSettings>)}
    </div>
    </div>
  );
}
CustomAuth.propTypes = {
  onSignIn: PropTypes.func.isRequired,
};
export default CustomAuth;




const styles = {
  container: { width: 700, margin: '0 auto', display: 'flex', flexDirection: 'column', justifycontent: 'center', padding: 20 },
  todo: {  marginBottom: 15 },
  input: { border: 'none', backgroundColor: '#ddd', marginBottom: 10, padding: 8, fontSize: 18 },
  todoName: { fontSize: 20, fontWeight: 'bold' },
  todoDescription: { marginBottom: 0 },
  button: { backgroundColor: 'black', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' }
}
