import './App.css';
import {BrowserRouter as Router, Routes, Route, NavLink} from 'react-router-dom';
import Home from './pages/Home';
import {Amplify,API, Auth} from 'aws-amplify';
import React, {useState} from 'react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from "./aws-exports";
import CustomAuth from './Auth';
import {invokeLambdaDtmvr} from './pages/Home'
Amplify.configure(awsExports);

function App() {
  const [user, setUser] = useState(null);  //makes you create state variables
  const queryParameters = new URLSearchParams(window.location.search)
  const [OrgId, setOrgId] = useState(""); 
  function handleSignIn(user) {
    setUser(user); // Aggiornare lo stato di user con l'utente autenticato
  }
  
  async function getToken(){

    try {
      const user = await Auth.currentAuthenticatedUser();
      const accessToken = user.signInUserSession.idToken.jwtToken;
      return accessToken;
    }
  
    catch (error) {
      console.log('Error getting token:  ', error);
    }
  
  }

  let dec_org_id = "";
  const valid_prefix = "00D";

  function saveOrgID (err, data) {
    if (err) {console.log('error');
    console.log(err);
    setOrgId("")
    }
    else{
      if (JSON.parse(data.Payload).hasOwnProperty("errorMessage")) {
        setOrgId("")
        return
      }
      dec_org_id = JSON.parse(JSON.parse(data.Payload).body).org_id
      if(! dec_org_id.startsWith(valid_prefix))
      {
        setOrgId("")
      }
      else
      {
        setOrgId(dec_org_id)
      }
    }
  }

  function decryptOrgId(orgId)
  {
    const headers = {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Methods': 'OPTIONS,POST,GET', 'Access-Control-Allow-Headers': '*'}
    const payload = {headers: headers, body: {org_id: orgId, operation: 'decrypt'}}
    invokeLambdaDtmvr('OrgIdCypher-dev', payload.body, saveOrgID)
  }
  decryptOrgId(queryParameters.get("org_id"))
  return (
    <div className="content">
      <p>{OrgId}</p>
      { user ? (
     <Router>
      <div>
        <NavLink className="content" activeclassname="active" to="/"> <h1> Home </h1> </NavLink>
      </div>
      
    <Routes>
      <Route path='/' element={<Home orgid={OrgId} user={user}/>}></Route>
    </Routes>
      </Router>
     )
     :(<CustomAuth onSignIn={handleSignIn} />)
    }
    </div>
  );
}
export default App;
